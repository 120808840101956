import { AuthenticationModal } from 'app/auth/components/AuthModal'
import { useAuth } from 'app/core/hooks'
import { FC, useState } from 'react'
import { Button, SiliconButtonProps } from 'silicon-design-system'

type SiliconButtonPropsNoRef = Omit<SiliconButtonProps, 'ref'>

interface AuthButtonProps extends SiliconButtonPropsNoRef {
  hideButtonAfterLogin?: boolean
}

export const AuthButton: FC<AuthButtonProps> = ({
  onClick: onClickAfterLoggedIn,
  hideButtonAfterLogin = false,
  ...props
}) => {
  const { isLoggedIn } = useAuth()
  const [showAuthModal, setShowAuthModal] = useState(false)
  const onCloseAuthModal = () => setShowAuthModal(false)
  const onOpenAuthModal = () => setShowAuthModal(true)

  const hide = isLoggedIn && hideButtonAfterLogin

  return (
    <>
      {!hide && <Button onClick={isLoggedIn ? onClickAfterLoggedIn : onOpenAuthModal} {...props} />}
      {!isLoggedIn && (
        <AuthenticationModal
          onClose={onCloseAuthModal}
          open={showAuthModal}
          callbackAfterLogin={onCloseAuthModal}
        />
      )}
    </>
  )
}
