export const iconsFolder = '/footericons/'

export const icons = [
  {
    src: 'facebook-circle.svg',
    alt: 'facebook',
    href: 'https://www.facebook.com/COMPFEST/',
  },
  {
    src: 'twitter.svg',
    alt: 'twitter',
    href: 'https://twitter.com/compfest',
  },
  {
    src: 'youtube.svg',
    alt: 'youtube',
    href: 'https://www.youtube.com/channel/UC03QmhwvQEWVI608A-RCEpw',
  },
  {
    src: 'instagram.svg',
    alt: 'instagram',
    href: 'https://www.instagram.com/compfest/?hl=en',
  },
  {
    src: 'linkedin.svg',
    alt: 'linkedin',
    href: 'https://www.linkedin.com/company/compfest',
  },
  {
    src: 'line.svg',
    alt: 'line',
    href: 'https://line.me/R/ti/p/%40compfest',
  },
  {
    src: 'medium.svg',
    alt: 'medium',
    href: 'https://medium.com/compfest',
  },
]

export const grandLaunchingItems = [
  {
    text: 'Map Grand Launching',
    href: '/mini-map',
  },
  {
    text: 'Grand Opening',
    href: '/grand-opening',
  },
  {
    text: 'Talkshow',
    href: '/talkshow',
  },
]

export const mainEventItems = [
  {
    text: 'Job and Intern Fair',
    href: '/jobfair',
  },
  {
    text: 'Shop',
    href: '/supermarket',
  },
  {
    text: 'Guidebook',
    href: 'https://www.compfest.id/me/booklet.pdf',
  },
  {
    text: 'Leaderboard',
    href: '/leaderboard',
  },
  {
    text: 'FAQ',
    href: '/faq',
  },
]

export const academyItems = [
  {
    text: 'Data Science',
    href: '/academy/dsa',
  },
  {
    text: 'Software Engineering',
    href: '/academy/sea',
  },
  {
    text: 'Startup',
    href: '/academy/sua',
  },
  {
    text: 'User Experience',
    href: '/academy/uxa',
  },
]

export const competitionItems = [
  {
    text: 'AI Innovation',
    href: '/competition/aic',
  },
  {
    text: 'Business-IT Case',
    href: '/competition/bizzit',
  },
  {
    text: 'Competitive&nbsp;<br class="block md:hidden" />Programming',
    href: '/competition/cpc',
  },
  {
    text: 'Capture the Flag',
    href: '/competition/ctf',
  },
  {
    text: 'Indie Game Ignite',
    href: '/competition/igi',
  },
  {
    text: 'MiniCase',
    href: '/competition/minicase',
  },
]

export const produkLainItems = [
  {
    text: 'XCelerate',
    href: '/xcelerate',
  },
  {
    text: 'CV Clinic',
    href: '/cv-clinic',
  },
  {
    text: 'COMPFEST.link',
    href: 'https://compfest.link/',
  },
  {
    text: 'Company Profile',
    href: 'https://partner.compfest.id/',
  },
  // {
  //   text: 'Vision',
  //   href: '/grand-opening',
  // },
  {
    text: 'Silicon Design System',
    href: 'https://compfest.link/silicon',
  },
  {
    text: 'COMPFEST Verify',
    href: 'https://verify.compfest.id',
  },
]
