import React, { createContext, ReactNode, useContext, useState } from 'react'
import { setSessionStorage } from '../hooks'

export type Category =
  | 'all'
  | 'apparel'
  | 'accessories'
  | 'electronic'
  | 'essentials'
  | 'voucher'
  | 'stationary'
  | 'sticker'

interface Filters {
  name: string
  category: Category
}

interface ProductFilterContextValueProps {
  name: string
  category: Category
  handleFilter: ({ name, category }) => void
}

interface ProductFilterContextProviderProps {
  children: ReactNode
}

const ProductFilterContext = createContext({} as ProductFilterContextValueProps)

export const useProductFilterContext = () => useContext(ProductFilterContext)

export const ProductFilterContextProvider: React.FC<ProductFilterContextProviderProps> = ({
  children,
}) => {
  const [state, setState] = useState<Filters>({ name: '', category: 'all' })

  const handleFilter = ({ name, category }) => {
    setSessionStorage('currentCategory', category)
    setState({ name, category })
  }

  const contextValue = {
    ...state,
    handleFilter,
  }

  return (
    <ProductFilterContext.Provider value={contextValue}>{children}</ProductFilterContext.Provider>
  )
}
