import { Link, Routes, useRouter } from 'blitz'
import { FC, ReactNode, Suspense, useEffect, useRef, useState } from 'react'
import { Button } from 'silicon-design-system'

import { LAYOUT_STYLES } from 'app/core/constants/styles'
import CompfestLamp from 'public/images/logo-lamp.svg'
import Compfest from 'public/images/logo.svg'
import { classNames } from 'utils/functions'

import { NavbarLinks } from 'app/core/components/Navbar/NavbarLinks'
import { FlexCenter } from 'app/core/components/Helper'
import { NavbarRight } from './NavbarRight'
import { Spinner } from 'silicon-design-system'
import HouseDoorFill from 'public/icons/house-door-fill.svg'
import SupermarketNavbar from './SupermarketNavbar'
export interface NavbarProps {
  textMidButton?: string
  iconMidButton?: ReactNode
}

const Navbar: FC<NavbarProps> = ({ textMidButton = 'Home', iconMidButton = <HouseDoorFill /> }) => {
  const [showNavLinks, setShowNavLinks] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const router = useRouter()
  const [navbarSupermarket, setNavbarSupermarket] = useState(false)

  const [bg, setBg] = useState('bg-transparent')

  const onOpenNavLinks = () => setShowNavLinks(true)
  const onCloseNavLinks = () => setShowNavLinks(false)

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 50) {
        setBg('bg-[#28293D]')
      } else {
        setBg('bg-transparent')
      }
    }

    return () => {
      window.onscroll = null
    }
  }, [])

  useEffect(() => {
    if (
      router.pathname === '/supermarket' ||
      router.pathname === '/supermarket/katalog' ||
      router.pathname === '/supermarket/katalog/[id]'
    ) {
      setNavbarSupermarket(true)
    }
    console.log(router)
  }, [router])

  return (
    <>
      <nav
        className={classNames(
          'fixed top-0 left-0 z-90 flex h-16 w-full flex-col items-center overflow-y-visible py-3 font-sans transition-colors duration-200 ease-in-out',
          LAYOUT_STYLES,
          bg,
        )}
      >
        <div className="relative mx-auto flex w-full max-w-[1280px] items-center justify-between">
          <Link href={Routes.HomePage()}>
            <a className="z-10 cursor-pointer" aria-label="COMPFEST">
              <Compfest className="hidden sm:block" />
              <CompfestLamp className="block sm:hidden" />
            </a>
          </Link>
          <FlexCenter className="absolute w-full flex-row">
            <div className="block sm:hidden">
              <Button
                variant="secondary"
                midIcon={iconMidButton}
                onClick={() => setShowNavLinks((prev) => !prev)}
                ref={buttonRef}
                textDecorationWidth={undefined}
                MozColumnGap={undefined}
                msAlignSelf={undefined}
                msJustifySelf={undefined}
                WebkitColumnGap={undefined}
                scrollbarTrackColor={undefined}
              />
            </div>
            <Button
              variant="secondary"
              leftIcon={textMidButton ? iconMidButton : undefined}
              midIcon={textMidButton ? undefined : iconMidButton}
              text={textMidButton}
              textTransform="capitalize"
              td="none"
              d="none"
              onTouchStart={() => setIsMobile(true)}
              onMouseEnter={(e) => {
                e.stopPropagation()
                if (!isMobile) onOpenNavLinks()
              }}
              onClick={(e) => {
                e.stopPropagation()
                if (isMobile) setShowNavLinks((prev) => !prev)
              }}
              _responsive={{ sm: { d: 'flex' } }}
              textDecorationWidth={undefined}
              MozColumnGap={undefined}
              msAlignSelf={undefined}
              msJustifySelf={undefined}
              WebkitColumnGap={undefined}
              scrollbarTrackColor={undefined}
            />
          </FlexCenter>
          <Suspense fallback={<Spinner />}>
            <NavbarRight />
          </Suspense>
        </div>
      </nav>
      <NavbarLinks show={showNavLinks} onClose={onCloseNavLinks} outerRef={buttonRef} />
      {navbarSupermarket && <SupermarketNavbar isOpen={showNavLinks} />}
    </>
  )
}

export default Navbar
