import React from 'react'

import { classNames } from 'utils/functions'
import { FlexCenterProps } from 'utils/types/props'

export const FlexCenter: React.FC<FlexCenterProps> = ({
  children,
  className = '',
  as = 'div',
  ...props
}) => {
  let flexDir = 'flex-col'
  if (className.includes('flex-row')) flexDir = ''

  const customClassNames = classNames(className, 'flex justify-center items-center', flexDir)
  return React.createElement(
    as,
    {
      ...props,
      className: customClassNames,
    },
    children,
  )
}
