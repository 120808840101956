import { useProductFilterContext } from 'app/core/context/ProductFilterContext'
import { Image, Link, useRouter } from 'blitz'
import React from 'react'

interface SupermarketNavbarProps {
  isOpen: boolean
}

const SupermarketNavbar: React.FC<SupermarketNavbarProps> = ({ isOpen }) => {
  const { pathname } = useRouter()

  const { handleFilter, category } = useProductFilterContext()

  return (
    <div
      className={`${pathname.includes('supermarket') ? 'fixed' : 'hidden'} ${
        isOpen ? 'top-36 duration-[900ms]' : 'duration-400 top-16 '
      } z-10 flex w-full items-center justify-between gap-5 bg-[#373951] p-4 transition-all ease-in-out`}
    >
      {pathname === '/supermarket/katalog' ? (
        <div className="relative ">
          <div className="absolute top-2 left-2">
            <Image src={'/me/search.svg'} width={18} height={18} />
          </div>
          <input
            className="w-[186px] rounded-xl border-2 border-white/30 bg-transparent py-[4px] pl-7 pr-[14px] text-sm outline-none placeholder:text-xs"
            placeholder="Cari barang incaranmu"
            onChange={(event) => handleFilter({ name: event.target.value, category })}
          />
        </div>
      ) : (
        <Link href={'/supermarket/katalog'}>
          <a className="flex items-center gap-2 rounded-xl bg-[#FFFFFF1A]/10 px-4 py-2 hover:bg-[#FFFFFF1A]/30">
            <span className="text-xs font-semibold">Cari barang di katalog</span>
            <Image src={'/me/search.svg'} width={16} height={16} />
          </a>
        </Link>
      )}
      <div className="flex gap-8">
        <Link href={'/supermarket/cart'}>
          <a className="flex items-center">
            <Image src={'/me/cart.svg'} width={24} height={24} />
          </a>
        </Link>
        <Link href={'/supermarket/transactions'}>
          <a className="flex items-center">
            <Image src={'/me/history.svg'} width={24} height={24} />
          </a>
        </Link>
      </div>
    </div>
  )
}

export default SupermarketNavbar
