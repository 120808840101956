import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Button } from 'silicon-design-system'

import {
  iconsFolder,
  icons,
  academyItems,
  produkLainItems,
  competitionItems,
  grandLaunchingItems,
  mainEventItems,
} from './data'
import DOMPurify from 'dompurify'

const Footer: FC = () => {
  const isOpen = false

  return (
    <footer className="text-[0.75 rem] flex flex-col rounded-t-[24px] bg-[#2D2F45] py-[40px] px-[10px] text-white sm:px-[20px] md:px-[100px]">
      {/* FOOTER_HEADER */}
      <div className="flex flex-col items-center md:flex-row md:justify-between">
        <Link href="/">
          <a>
            <Image alt="compfest" src="/images/logo.svg" width={184} height={48} />
          </a>
        </Link>
        <div className="flex gap-x-[18px]">
          {icons.map((icon, idx) => {
            return (
              <a key={idx} href={icon.href} target="_blank" rel="noreferrer">
                <Image alt={icon.alt} src={iconsFolder + icon.src} width={24} height={24} />
              </a>
            )
          })}
        </div>
      </div>

      {/* FOOTER_BODY */}
      <div className="mt-8 flex flex-wrap justify-center gap-[33px] md:justify-start">
        <div className="w-[12ch] min-w-[12ch] max-w-[12ch] sm:w-[18ch] sm:min-w-[18ch] sm:max-w-[18ch]">
          <h5 className="mb-1 font-bold">Academy</h5>
          <ul className="font-normal">
            {academyItems.map((item, idx) => {
              return (
                <Link key={idx} href={item.href}>
                  <a>
                    <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                  </a>
                </Link>
              )
            })}
          </ul>
        </div>
        <div className="w-[12ch] min-w-[12ch] max-w-[12ch] sm:w-[18ch] sm:min-w-[18ch] sm:max-w-[18ch]">
          <h5 className="mb-1 font-bold">Competition</h5>
          <ul className="font-normal">
            {competitionItems.map((item, idx) => {
              return (
                <Link key={idx} href={item.href}>
                  <a>
                    <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                  </a>
                </Link>
              )
            })}
          </ul>
        </div>
        <div className="w-[12ch] min-w-[12ch] max-w-[12ch] sm:w-[18ch] sm:min-w-[18ch] sm:max-w-[18ch]">
          <h5 className="mb-1 font-bold">Grand Launching</h5>
          <ul className="font-normal">
            {grandLaunchingItems.map((item, idx) => {
              return (
                <Link key={idx} href={item.href}>
                  <a>
                    <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                  </a>
                </Link>
              )
            })}
          </ul>
        </div>
        <div className="w-[12ch] min-w-[12ch] max-w-[12ch] sm:w-[18ch] sm:min-w-[18ch] sm:max-w-[18ch]">
          <h5 className="mb-1 font-bold">Main Event</h5>
          <ul className="font-normal">
            {mainEventItems.map((item, idx) => {
              return (
                <Link key={idx} href={item.href}>
                  <a>
                    <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                  </a>
                </Link>
              )
            })}
          </ul>
        </div>
        <div className="w-[12ch] min-w-[12ch] max-w-[12ch] sm:w-[18ch] sm:min-w-[18ch] sm:max-w-[18ch]">
          <h5 className="mb-1 font-bold">Produk Lain</h5>
          <ul className="font-normal">
            {produkLainItems.map((item, idx) => {
              return (
                <Link key={idx} href={item.href}>
                  <a>
                    <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                  </a>
                </Link>
              )
            })}
          </ul>
        </div>
      </div>

      {/* FOOTER_FOOTER */}
      <div className="mt-8 flex flex-col items-center md:items-start">
        <p className="text-center">&copy; 2022 COMPFEST. All rights reserved.</p>
        <div className="mt-2 flex items-center gap-x-1">
          <p>Built with</p>
          <Link href="https://compfest.link/silicon">
            <a className="flex gap-x-1" target="_blank" rel="noreferrer">
              <Image alt="silicon" src="/footericons/silicon.svg" width={24} height={24} />
              <p className="font-bold">Silicon</p>
            </a>
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
