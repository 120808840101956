import { FC, RefObject, useRef } from 'react'

import { useOutsideClick } from 'app/core/hooks'
import { Transition } from '@headlessui/react'
import { classNames } from 'utils/functions'

import { NavbarBox } from './NavbarBox'
import { NAVBAR_LINKS } from 'app/core/constants/navbarLinks'

interface NavbarLinksProps {
  show: boolean
  onClose: () => unknown
  className?: string
  outerRef?: RefObject<HTMLElement>
}

export const NavbarLinks: FC<NavbarLinksProps> = ({ show, onClose, className, outerRef }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick(ref, (event) => {
    if (outerRef?.current?.contains(event?.target as HTMLElement)) return
    onClose()
  })

  return (
    <Transition
      show={show}
      onMouseLeave={onClose}
      className={classNames(
        className,
        'shadow-navLinks supersmall-scrollv fixed top-16 z-80 h-20 w-full overflow-auto bg-[#2D2F45] px-4 py-2 md:h-28 md:py-4',
      )}
      enter="transition-transform duration-500"
      enterFrom="-translate-y-40"
      enterTo="translate-y-0"
      leave="transition-transform duration-500"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-40"
    >
      <div className="relative flex h-full min-w-fit items-center justify-center" ref={ref}>
        {NAVBAR_LINKS.map((navLink) => (
          <NavbarBox key={navLink.text} {...navLink} />
        ))}
      </div>
    </Transition>
  )
}
