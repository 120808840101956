import { FC, ReactNode } from 'react'
import { NavbarTab } from 'app/core/components/Navbar/NavbarTab'
import BoxArrowLeftGoingIn from 'public/icons/box-arrow-left-going-in.svg'
import BoxArrowRight from 'public/icons/box-arrow-right.svg'
import { useAuth } from 'app/core/hooks'
import { NotificationIcon, PrivateNotifications } from 'app/notifications/components'
import { AuthButton } from '../Button'

export interface NavbarProps {
  textMidButton?: string
  iconMidButton?: ReactNode
}

export const NavbarRight: FC = () => {
  const { isLoggedIn } = useAuth()

  let textButton = 'Login'
  if (isLoggedIn) textButton = 'Log out'

  return (
    <>
      {!isLoggedIn ? (
        <>
          <AuthButton
            midIcon={isLoggedIn ? <BoxArrowRight /> : <BoxArrowLeftGoingIn />}
            brad={12}
            p="8px 12px"
            z={10}
            d="block"
            _responsive={{ sm: { d: 'none' } }}
          />
          <AuthButton text={textButton} z={10} d="none" _responsive={{ sm: { d: 'block' } }} />
        </>
      ) : (
        <>
          <NotificationIcon className="mr-44 hidden cursor-pointer sm:block" />
          <NavbarTab />
          <PrivateNotifications />
        </>
      )}
    </>
  )
}
