export const NAVBAR_LINKS = [
  {
    text: 'Home',
    icon: '/icons/house-door.svg',
    className: 'bg-grape',
  },
  {
    text: 'Academy',
    icon: '/icons/iconAcad.svg',
    className: 'bg-blueberryLight ml-2',
    href: '/academy',
  },
  {
    text: 'Competition',
    icon: '/icons/iconCompe.svg',
    className: 'bg-peachDark ml-2',
    href: '/competition',
  },
  {
    text: 'CV Clinic',
    icon: '/icons/CV-Clinic.svg',
    className: 'bg-[#7F98BC] ml-2',
    href: '/cv-clinic',
  },
  {
    text: 'Dashboard',
    icon: '/icons/graph-dashboard.svg',
    className: 'bg-pacific ml-2',
    href: '/dashboard',
  },
//   {
//     text: 'Guidebook',
//     icon: '/icons/Guidebook.svg',
//     className: 'bg-pacific ml-2',
//     href: 'https://www.compfest.id/me/booklet.pdf',
//   },
  {
    text: 'Job Fair',
    icon: '/icons/JobAndInternFair.svg',
    className: 'bg-blueberryLight ml-2',
    href: '/jobfair',
  },
  {
    text: 'Leaderboard',
    icon: '/icons/Leaderboard.svg',
    className: 'bg-red-600 ml-2',
    href: '/leaderboard',
  },
  {
    text: 'Map Grand Launching',
    icon: '/icons/map.svg',
    className: 'bg-mint ml-2',
    href: '/mini-map',
  },
  {
    text: 'Shop',
    icon: '/icons/Shop.svg',
    className: 'bg-orange-600 ml-2',
    href: '/supermarket',
  },
  {
    text: 'Talkshow',
    icon: '/icons/mic.svg',
    className: 'bg-gum ml-2',
    href: '/talkshow',
  },
  {
    text: 'XCelerate',
    icon: '/icons/XCelerate.svg',
    className: 'bg-gradient-to-t from-[#7366FE] to-[#FAA5AC] ml-2',
    href: '/xcelerate',
  },
  //   {
  //     text: 'by.COMPFEST',
  //     icon: '/icons/cart4.svg',
  //     className: 'bg-blueberry',
  //   },
]
