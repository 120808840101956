import { Image, Link } from 'blitz'
import { FC } from 'react'

import { classNames } from 'utils/functions'

export interface NavbarBoxProps {
  className?: string
  icon: string
  text: string
  href?: string
}

export const NavbarBox: FC<NavbarBoxProps> = ({ className, icon, text, href = '/' }) => {
  return (
    <Link href={href}>
      <div
        className={classNames(
          className,
          'box-border flex cursor-pointer items-center justify-center rounded-lg px-2 capitalize md:rounded-xl',
          'relative h-full w-33 overflow-hidden text-center font-sans text-base font-bold md:w-44',
        )}
      >
        <h3>{text}</h3>
        <div className="absolute scale-70 transition-all duration-500 hover:scale-90">
          <Image src={icon} width={144} height={144} alt={text} />
        </div>
      </div>
    </Link>
  )
}
